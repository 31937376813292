<template>
  <el-container>
    <el-row style="width: 100%">
      <el-col :span="24">
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          label-width="200px"
          label-position="right"
          size="mini"
        >
          <el-form-item label="账号" prop="account">
            <el-input v-model="form.account"></el-input>
          </el-form-item>
          <el-form-item label="姓名" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>

          <el-form-item label="密码" prop="password">
            <el-input
              v-model="form.password"
              :placeholder="form.guid ? '为空则不更新原密码' : ''"
            ></el-input>
          </el-form-item>

          <el-form-item label="关联项目">
            <div>
              <el-transfer
                :titles="['未关联', '已关联']"
                :button-texts="['取消关联', '添加关联']"
                filterable
                :filter-method="filterMethod"
                filter-placeholder="请输入"
                v-model="form.projectGuidList"
                @change="editObject"
                :data="copyList2">
              </el-transfer>
            </div>
          </el-form-item>
          <el-form-item label="项目">
            <el-tabs @tab-click="getProjectGuid">
              <el-tab-pane v-for="item in form.projectGuidList" :key="item" :label="getInfo(item,'label')" :vvv="item">
                <el-row :gutter="20">
                  <el-col :span="6"><p>小区</p></el-col>
                  <el-col :span="6"><p>问卷</p></el-col>
                  <el-col :span="6"><p>阶段</p></el-col>
                  <el-col :span="6"></el-col>
                </el-row>
                <el-row :gutter="20" v-for="(projectRelation,index) in form.projectRelationList" :key="index">
                  <div v-if="item == projectRelation.projectGuid">
                    <el-col :span="6">
                      <el-form-item>
                          <el-select filterable v-model="projectRelation.communityGuid" clearable placeholder="请选择小区" style="width:100%" @change="$forceUpdate()">
                            <el-option
                              v-for="community in getInfo(item,'communityList')"
                              :key="community.guid"
                              :label="community.name"
                              :value="community.guid">
                            </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                          <el-select v-model="projectRelation.patrolSurveyGuid" clearable placeholder="请选择问卷" style="width:100%" @change="patrolSurveyGuidChange(projectRelation.patrolSurveyGuid,index,item)">
                            <el-option
                              v-for="patrolSurvey in getInfo(item,'patrolSurveyList')"
                              :key="patrolSurvey.guid"
                              :label="patrolSurvey.name"
                              :value="patrolSurvey.guid">
                            </el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="6">
                          <el-select v-model="projectRelation.patrolTimes" clearable placeholder="请选择次数" style="width:100%" @change="$forceUpdate()" @click.native="clickNative(projectRelation.patrolSurveyGuid,item)">
                            <el-option
                              v-for="patrolTimes of patrolTimesArrary1"
                              :key="patrolTimes"
                              :label="patrolTimes"
                              :value="patrolTimes">
                            </el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="6">
                      <el-button type="danger" size="mini" @click="delProjectRelation(index)">删除</el-button>
                    </el-col>
                  </div>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="6"><el-button type="primary" size="mini" @click="addProjectRelation1(item)">添加</el-button></el-col>
                  <el-col :span="6"><el-button type="primary" size="mini" @click="addProjectRelation(item)">批量添加</el-button></el-col>
                </el-row>
              </el-tab-pane>
            </el-tabs>
          </el-form-item>
          <el-form-item style="float:right">
            <el-button type="danger" @click="onDelete" v-if="form.guid !== ''">删除</el-button>
            <el-button type="primary" @click="onSubmit">保存</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>

    <el-dialog title="添加" width="50%" :visible.sync="addVisible">
      <el-form>
        <el-form-item label="小区">
          <el-cascader v-model="guidList" style="width: 100%" :options="communityTreeData" :props='{value:"value",label:"label",multiple: true }' collapse-tags clearable></el-cascader>
        </el-form-item>
        <el-form-item label="问卷">
          <el-select v-model="addForm.surveryGuid" clearable placeholder="请选择问卷" style="width:100%" @change="patrolSurveyGuidChange2">
            <el-option
              v-for="item in surveryArrary"
              :key="item.guid"
              :label="item.name"
              :value="item.guid">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="阶段">
          <el-select v-model="addForm.patrolTimes" clearable placeholder="请选择次数" style="width:100%" @change="$forceUpdate()">
              <el-option
                v-for="times of patrolTimesArrary"
                :key="times"
                :label="times"
                :value="times">
              </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addVisible = false">取 消</el-button>
        <el-button type="primary" @click="doAddCommunity">确 定</el-button>
      </div>
    </el-dialog>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      form: {
        guid: "",
        account: "",
        password: "",
        name: "",
        projectGuidList: [],
        projectList: [],
        nestedGuidList: [],
        projectRelationList:[]
      },
      userInfo: {},
      allDeptPosition: [],
      rules: {
        account: [{required: true, message: "账号不能为空", trigger: "blur"}],
        name: [{required: true, message: "姓名不能为空", trigger: "blur"}],
        password: [{required: true, message: "密码不能为空", trigger: "blur"}],
      },
      projectList: [],
      hehe: [],
      addVisible: false,
      addForm: {
        communityList: [],
        surveryGuid: '',
        patrolTimes: ''
      },
      communityTreeData: [],
      guidList: ['','','','','',''],
      surveryArrary: [],
      patrolTimesArrary: [],
      patrolTimesArrary1: [],
      surveryId: '',
      patrolSurveyGuid: '',
      copyList2: [],
      copyListO: [],
      currentTab:null
    };
  },
  created() {
    let self = this;
    self.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    self.getUserDetail(self.$route.query.guid || null);
    self.getProjectList();
    
  },
  watch: {
    form: {
      handler() {
        this.clearCommunity()
      },
      deep: true
    }
  },
  methods: {
    getUserDetail(guid) {
      let self = this;
      if (guid) {
        self.$axios
          .get("/staff/get/" + guid, {
            headers: { token: self.userInfo.token },
          })
          .then(function (response) {
            // self.form = response.data.data;
            self.form.guid = response.data.data.guid
            self.form.account = response.data.data.account;
            self.form.name = response.data.data.name;
            self.form.password = "";
            self.form.projectRelationList = response.data.data.projectRelationList;
            self.form.projectGuidList = self.$underscore.keys(
              self.$underscore.groupBy(
                self.$underscore.map(response.data.data.projectRelationList, function(_el) {
                return _el.projectGuid;
              })
            ))
            console.log("self.form.projectGuidList = ", self.form.projectGuidList);
            console.log('self.form=',self.form);
            self.rules.password = [];
            console.log(22);
            console.log(self.form);
            
          })
          .catch(function (error) {
            self.$message({
              message: error,
              type: "warning",
            });
          });
      }else{
        // self.getPatrolType();
      }
    },
    onSubmit() {
      let self = this;
      let params = self.form;
      console.log(params);
      self.$refs["form"].validate((valid) => {
        if (valid) {
          let data = JSON.parse(JSON.stringify(params));
          self.$axios
            .post("/staff/saveOrUpdate", JSON.stringify(data), {
              headers: {
                "content-type": "application/json",
                token: self.userInfo.token,
              },
            })
            .then(function () {
              self.$message({
                message: "操作成功",
                type: "success",
              });
              self.$router.push({ name: "staff_list" });
            })
            .catch(function (error) {
              self.$message({
                message: error.response.data.message,
                type: "warning",
              });
            });
        } else {
          self.$message({
            message: "请输入正确的用户信息",
            type: "warning",
          });
          return false;
        }
      });
    },
    onDelete() {
      let self = this;
      self
        .$confirm("确定删除 " + (self.form.account || "未命名用户") + "？")
        .then(() => {
          self.$axios
            .delete("/staff/delete", {
              data: { guid: self.form.guid },
              headers: { token: self.userInfo.token },
            })
            .then(() => {
              self.$message({
                message: "删除成功",
                type: "success",
              });
              self.$router.push({ name: "staff_list" });
            })
            .catch((error) => {
              self.$message({
                message: error.response.data.message,
                type: "warning",
              });
            });
        })
        .catch(() => {});
    },
    getProjectList() {
      let self = this;
      self.$axios
        .get("/project/list2", {
          headers: { token: self.userInfo.token },
        })
        .then(function (response) {
          console.log('copyList2=',self.copyList2);
          self.projectList = self.$underscore.map(response.data.data, function(_el) {
            let _temp = self.$underscore.clone(_el);
            _temp.key = _el.guid;
            _temp.label = _el.name;
            return _temp;
            // return {key: _el.guid, label: _el.name};
          });
          console.log(self.projectList);
          console.log('---------');
          self.$underscore.each(self.form.projectGuidList, function(_el) {
            let _temp = self.$underscore.find(self.projectList, function(sub_el) {
              return sub_el.guid == _el;
            })
            if (_temp) {
              self.form.projectList.push(_temp);
              self.form.nestedGuidList.push({
                guid: _temp.guid,
                communityGuidList: [],
                patrolSurveyGuidList: [],
              })
            }
          })
          self.copyList2 = JSON.parse(JSON.stringify(self.projectList))
          self.copyListO = JSON.parse(JSON.stringify(self.projectList))
          console.log(self.projectList);
        })
        .catch(function (error) {
          self.$message({
            message: error.response.data.message,
            type: "warning",
          });
        });
    },
    filterMethod(query, item) {
      // return true;
      return item.label.indexOf(query) > -1;
    },
    communityName(communityGuid, projectGuid) {
      let self = this;
      console.log("communityGuid = ", communityGuid);
      console.log("projectGuid = ", projectGuid);
      let _project = self.$underscore.find(self.projectList, function(project_el) {
        return project_el.guid == projectGuid;
      })
      let _community = self.$underscore.find(_project.communityList, function(community_el) {
        return community_el.guid == communityGuid;
      })
      if (_community) {
        return _community.name;
      }
      return "不存在的小区"
    },
    communityList(projectGuid){
      let self = this;
      let _project = self.$underscore.find(self.projectList, function(project_el) {
        return project_el.guid == projectGuid;
      })
      return _project.communityList;
    },
    getInfo(guid,info,other,oGuid){
      if(this.copyList2.length>0){
        let item = this.copyList2.find(function(e){return e.guid == guid});
        if(other) {
          if(oGuid) {
            item[info].forEach((_item,_index) => {
              if(_item.guid == oGuid) {
                this.patrolTimesArrary = item[info][_index][other]
                this.patrolTimesArrary1 = item[info][_index][other]
                return item[info][_index][other]
              }
            })
          } else {
            return item[info][0][other]
          }
        }
        else{return item[info]}
      }
    },
    // 批量添加
    addProjectRelation(guid){
      let self = this
      console.log('guid=',guid);
      self.addForm = {
        communityList: [],
        surveryGuid: '',
        patrolTimes: ''
      }
      self.guidList = ['','','','','','']
      self.communityTreeData = []
      self.surveryId = guid
      self.surveryArrary = self.getInfo(guid,'patrolSurveyList')
      self.projectList.forEach(item => {
        if(item.guid == guid) {
          self.communityTreeData = self.getTreeData(item.communityTreeData)
        }
      })
      this.addVisible = true
    },
    // 添加
    addProjectRelation1(item) {
      this.form.projectRelationList.push({
        communityGuid:null,
        patrolSurveyGuid:null,
        patrolTimes:null,
        projectGuid:item
      })
      this.$forceUpdate()
    },
    getTreeData(data){
      for(var i=0;i<data.length;i++){
        if(data[i].children==undefined || data[i].children.length==0){
          data[i].children=undefined;
        }else {
          this.getTreeData(data[i].children);
        }
      }
      return data;
    },
    // 添加时,问卷发生改变
    patrolSurveyGuidChange2(guid){
      this.addForm.patrolTimes = null
      this.getInfo(this.surveryId,'patrolSurveyList','patrolTimes',guid)
      this.$forceUpdate()
    },
    // 确认添加
    doAddCommunity() {
      this.guidList.forEach(item => {
        this.addForm.communityList.push(item[5])
        this.form.projectRelationList.push({
          communityGuid:item[5],
          patrolSurveyGuid:this.addForm.surveryGuid,
          patrolTimes:this.addForm.patrolTimes,
          projectGuid:this.surveryId
        })
      })
      this.addVisible = false
    },
    delProjectRelation(index){
      this.form.projectRelationList.splice(index,1)
      this.$forceUpdate()
    },
    patrolSurveyGuidChange(guid,index,item){
      this.getInfo(item,'patrolSurveyList','patrolTimes',guid)
      this.form.projectRelationList[index].patrolTimes = null
      this.$forceUpdate()
    },
    editObject(nowValue,direction,keyArr) {
      let self = this
      console.log(nowValue);
      console.log(direction);
      console.log(keyArr);
      if(direction == 'left') {
        keyArr.forEach(item => {
          self.form.projectRelationList.forEach((_item,index) => {
            if(_item.projectGuid == item) {
              self.form.projectRelationList.splice(index,1)
              }
          })
        })
        console.log(self.form);
      }
    },
    clickNative(guid,item) {
      this.getInfo(item,'patrolSurveyList','patrolTimes',guid)
      this.$forceUpdate()
    },
    // 清理分配完小区
    clearCommunity() {
      let self = this;
      let guid = self.currentTab ? self.currentTab : self.form.projectGuidList[0];
      console.log('guid',guid);
      if(guid) {
        let currentCommunityList
        if(self.projectList.length) {
          currentCommunityList = JSON.parse(JSON.stringify(self.copyListO.find(function(e){return e.guid == guid}).communityList))
        }
        let index = self.copyListO.findIndex(function(e){return e.guid == guid});
        console.log('currentCommunityList',currentCommunityList);
        if(currentCommunityList){
          for(let item of self.form.projectRelationList){
            if(item.communityGuid || item.name){
              let a = currentCommunityList.find(function(e) {
                return e.guid == item.communityGuid
              })
              if(a){
                a.name +='★'
              }
            }
          }
          self.copyList2[index]['communityList'] = currentCommunityList
        }
      }
    },
    // 获取当前所选项目的id
    getProjectGuid(s) {
      this.currentTab = s.$attrs.vvv;
      console.log(this.currentTab)
    }
  },
};
</script>

<style>
.el-transfer-panel{
  width: 30%;
}
</style>
